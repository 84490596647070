// import packages
import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import {Pagination} from 'antd';
import moment from "moment";

//Import assets
import './News.scss'

//Import components

//Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetBlogs, GetLastBlogs} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {useState} from "react";
import {PagLeftIcon, PagRightIcon, ShareIcon} from "../../assets/images";
import {PAGE_GET_COUNT, PAGE_NEWS_COUNT} from "../../constants/constType";
import NewsCard from "../../components/uiElements/card/newsCard/NewsCard";
import Subscribe from "../../components/subscribe/Subscribe";
import NewsModal from "../../components/uiElements/modal/newsModal/NewsModal";


function Blog(props){
    const {staticTexts,blogs,lastNews} = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [modalOpen,setModalOpen] = useState(false)
    const [news,setNews] = useState()
    const isFetching = useRef(false)

    function toggleModalOpen(news){
        setNews(news)
        setModalOpen(!modalOpen)
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetBlogs()
        props.GetLastBlogs()
    },[])

    async function onPageChanged (news) {
        let count = news -1
        isFetching.current = true;
        const configInfo = {
            reset: true,
            offset: (count * PAGE_NEWS_COUNT) + PAGE_GET_COUNT,
            limit: PAGE_NEWS_COUNT,
        }
        await props.GetBlogs(configInfo)
        setCurrentPage(news);
        window.scrollTo({
            top: 0
        });
    }

    console.log(lastNews,'lastNews')
    console.log(blogs,'blogs')
    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PagLeftIcon className={'arrow_icon'}/>
                <span>
                    {staticTexts?.blog_page_prev}
                </span>
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                <span>
                    {staticTexts?.blog_page_next}
                </span>
                <PagRightIcon className={'arrow_icon'}/>
            </a>;
        }
        return originalElement;
    };

    return <div className={'blog-wrapper'}>
        <div className={'blog-header'}>
            <div className={'title'}>
                {staticTexts?.blog_page_subscribe_title}
            </div>
            <div className={'description'}>
                {staticTexts?.blog_page_subscribe_description}
            </div>
        </div>
        <div className={'last-news-wrapper'}>
            <div className={'last-news-title'}>
                {staticTexts?.blog_page_last_news_title}
            </div>
            {lastNews?.itemsList?.length &&
                <div className={'last-news'}>
                    <div className={`first-news`} onClick={() => toggleModalOpen(lastNews?.itemsList[0])}>
                        <div className={'last-news-img'}>
                            <img src={generateImageMediaUrl(lastNews?.itemsList[0]?.mediaMain?.path)}/>
                        </div>
                        <div className={'last-news-info'}>
                            <div className={'date'}>
                                {moment(lastNews?.itemsList[0]?.createdAt).format('ll')}
                            </div>
                            <div className={'title-wrapper'}>
                                <span className={'title'}>{lastNews?.itemsList[0]?.title}</span>
                                <div  onClick={() => toggleModalOpen(lastNews?.itemsList[0])} className={'share'}>
                                    <ShareIcon/>
                                </div>
                            </div>
                            <div className={'description'}>
                                {lastNews?.itemsList[0]?.description}
                            </div>
                        </div>
                    </div>
                    <div className={'right-news-block'}>
                        <div className={`other-news`} onClick={() => toggleModalOpen(lastNews?.itemsList[1])}>
                            <div className={'img-wrapper'}>
                                <div className={'last-news-img'}>
                                    <img src={generateImageMediaUrl(lastNews?.itemsList[1]?.mediaMain?.path)}/>
                                </div>
                            </div>
                            <div className={'last-news-info'}>
                                <div className={'date'}>
                                    {moment(lastNews?.itemsList[1]?.createdAt).format('ll')}
                                </div>
                                <div className={'title-wrapper'}>
                                    <span className={'title'}>{lastNews?.itemsList[1]?.title}</span>
                                    <div onClick={() => toggleModalOpen(lastNews?.itemsList[1])} className={'share'}>
                                        <ShareIcon/>
                                    </div>
                                </div>
                                <div className={'description'}>
                                    {lastNews?.itemsList[1]?.description}
                                </div>
                            </div>
                        </div>
                        <div className={`other-news`} onClick={() => toggleModalOpen(lastNews?.itemsList[2])}>
                            <div className={'img-wrapper'}>
                                <div className={'last-news-img'}>
                                    <img src={generateImageMediaUrl(lastNews?.itemsList[2]?.mediaMain?.path)}/>
                                </div>
                            </div>
                            <div className={'last-news-info'}>
                                <div className={'date'}>
                                    {moment(lastNews?.itemsList[2]?.createdAt).format('ll')}
                                </div>
                                <div className={'title-wrapper'}>
                                    <span className={'title'}>{lastNews?.itemsList[2]?.title}</span>
                                    <div onClick={() => toggleModalOpen(lastNews?.itemsList[2])} className={'share'}>
                                        <ShareIcon/>
                                    </div>
                                </div>
                                <div className={'description'}>
                                    {lastNews?.itemsList[2]?.description}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        {
            blogs?.itemsList?.length ? <div className={'news-wrapper'}>
                <div className={'news-wrapper-title'}>
                    {staticTexts?.blog_page_all_news_title}
                </div>
                {blogs?.itemsList?.length &&  <div className={'news'}>
                    {blogs?.itemsList.map(item => {
                        return <NewsCard item={item} key={item.id}/>
                    })}
                </div>}
            </div> : ''
        }
        <Pagination current={currentPage}
                    onChange={onPageChanged}
                    simple={window.innerWidth < 1100 ? true : false}
                    total={blogs?.count - 3}
                    defaultPageSize={6}
                    hideOnSinglePage={true}
                    itemRender={itemRender}
                    className={`pagination`}
        />
        <Subscribe staticTexts={staticTexts}/>
        <NewsModal toggleModalOpen={toggleModalOpen} modalOpen={modalOpen} news={news}/>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts',
        'categories',
        'blogPage',
        'blogs',
        'lastNews',
    ])
};
const mapDispatchToProps = {
    GetBlogs,
    GetLastBlogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);