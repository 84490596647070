// import packages
import React, {Component, useEffect, useRef, useState} from "react";
import Slider from "react-slick";

//Import styles
import "./HomePartners.scss";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";

//Import Components

// Import utils


function HomePartners(props){
    const {data,staticTexts, partners} = props

    let sliderRef = useRef(null);
    const settings = {
        dots: false,
        arrows: false,
        autoplay:true,
        infinite:true,
        speed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode:  window.innerWidth < 700,
        centerPadding: '60px 0 0',
        ref: partner => (sliderRef = partner),
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '60px 0 0',
                }
            },
        ]
    };

    return !!partners?.length && <div className="partners-wrapper">

        <div className="partners-list-wrapper">
            {
                <Slider {...settings}>
                    {partners?.map(item => {
                        return <div  key={item}>
                            <div className="partner-item" key={item.id}>
                                <div className="partner-content">
                                    <img src={generateImageMediaUrl(item?.icon?.path)} className="fade-image"
                                         title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                         alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                </div>
                            </div>
                        </div>
                    })}
                </Slider>
            }
        </div>
    </div>
}
export default HomePartners